import { VersionedStorage, EventEmitter } from 'o365-modules';

interface ISizerOptions {
    element: HTMLElement;
    sizer: HTMLElement;
    collapse: boolean;
    expand: boolean;
    horizontal: boolean;
    id: string;
    direction: 'bottom' | 'top' | 'left' | 'right';
    setIconsFn: (pIcon: 'left' | 'right' | 'horizontal' | 'top' | 'bottom' | 'vertical') => void;
    maxWidth?: string;
    maxHeight?: string;
    minWidth?: string;
    minHeight?: string;
    initiallyCollapsed?: boolean;
    initiallyExpanded?: boolean;
    bootstrapTransitionPromise?: { value?: Promise<void> };
    collapseAt?: string;
};

export default class Sizer {
    element: HTMLElement;
    sizer: HTMLElement;
    sibling: HTMLElement | null;
    collapse: boolean;
    expand: boolean;
    /**
     * Horizontal means height sizer
     */
    horizontal: boolean;
    id: string;
    direction: ISizerOptions['direction'];
    setIconsState: ISizerOptions['setIconsFn'];
    maxWidth?: string
    maxHeight?: string
    minWidth?: string
    minHeight?: string
    collapseAt?: string
    initiallyCollapsed: boolean;
    initiallyExpanded: boolean;
    debounce: number | null;
    bootstrapTransitionPromise?: { value?: Promise<void> };

    elementIsFixed?: boolean;
    siblingIsFixed?: boolean;
    elementIsMin?: boolean;
    siblingIsMin?: boolean;
    listener = false;

    eventHanlder?: EventEmitter<{
        'Resizing': (el: HTMLElement, sb: HTMLElement) => void,
        'Resized': (el: HTMLElement, sb: HTMLElement) => void,
    }>;

    isLoadedFromLocalStorage = false;

    collapseState?: null | 'collapsedLeft' | 'collapsedRight' | 'collapsedTop' | 'collapsedBottom';

    private _storage: VersionedStorage<ISizerStorageItem, ISizerStorageBaseline>;
    private _props;
    private _vueEmit: Function;

    ANIMATION_DURATION = 300;

    constructor(pElement: HTMLElement, pOptions: ISizerOptions, props: any, vueEmit: Function) {

        this.element = pElement;
        this.sizer = pElement.firstElementChild as HTMLElement;
        this.collapse = pOptions.collapse;
        this.expand = pOptions.expand;
        this.horizontal = pOptions.horizontal ? true : false
        this.id = pOptions.id;
        this.direction = pOptions.direction;
        this.setIconsState = pOptions.setIconsFn
        this.maxWidth = pOptions.maxWidth
        this.maxHeight = pOptions.maxHeight
        this.minWidth = pOptions.minWidth
        this.minHeight = pOptions.minHeight
        this.bootstrapTransitionPromise = pOptions.bootstrapTransitionPromise;
        this.collapseAt = pOptions.collapseAt;
        this._vueEmit = vueEmit;

        if (props.useEvents) {
            this.eventHanlder = new EventEmitter();
        }

        this.initiallyCollapsed = pOptions.initiallyCollapsed ?? false;
        this.initiallyExpanded = pOptions.initiallyExpanded ?? false;

        this._props = props;

        this._storage = new VersionedStorage<ISizerStorageItem, ISizerStorageBaseline>({
            id: this.id,
            baseline: {
                collapse: this.collapse,
                expand: this.expand,
                height: this._props.height,
                initiallyCollapsed: this.initiallyCollapsed,
                initiallyExpanded: this.initiallyExpanded,
                maxHeight: this._props.maxHeight,
                maxWidth: this._props.maxWidth,
                minHeight: this._props.minHeight,
                minWidth: this._props.minWidth,
                width: this._props.width
            },
            hashFunction: pBase => {
                const compressedValues = [];
                if (pBase.collapse) {
                    compressedValues.push('c');
                }
                if (pBase.expand) {
                    compressedValues.push('e');
                }
                if (pBase.initiallyCollapsed) {
                    compressedValues.push('ic');
                }
                if (pBase.initiallyExpanded) {
                    compressedValues.push('ie');
                }
                if (pBase.height) {
                    compressedValues.push(`h=${pBase.height}`);
                }
                if (pBase.minHeight) {
                    compressedValues.push(`mh=${pBase.minHeight}`);
                }
                if (pBase.maxHeight) {
                    compressedValues.push(`mxh=${pBase.maxHeight}`);
                }
                if (pBase.width) {
                    compressedValues.push(`w=${pBase.width}`);
                }
                if (pBase.minWidth) {
                    compressedValues.push(`mw=${pBase.minWidth}`);
                }
                if (pBase.maxWidth) {
                    compressedValues.push(`mxw=${pBase.maxWidth}`);
                }
                return compressedValues.join(';');
            }
        });

        this.sibling = null;
        this.debounce = null;
        this.initResizeEvents();
        this.collapseState = this._getCollapseState();
    }

    initResizeEvents() {
        if (this.element.nextElementSibling) {
            if (this.horizontal) {
                this.sizer.style.bottom = "-3px"
            } else {
                this.sizer.style.right = "-3px"
            }
        } else {
            if (this.horizontal) {
                this.sizer.style.top = "-3px"
            } else {
                this.sizer.style.left = "-3px"
            }
        }

        this.sizer.addEventListener("mousedown", this.onMouseDown);
        this.sizer.addEventListener("touchstart", this.onMouseDown, { passive: true });
        this.sibling = (this.element.nextElementSibling ? this.element.nextElementSibling : this.element.previousElementSibling) as HTMLElement;

        let vLocalStorage = this._props.skipLocalStorage ? null : this._getFromLocalStorage();

        let animationsAdded = false;

        if (vLocalStorage) {
            if (vLocalStorage.collapsed) {
                this.sizer.classList.add(vLocalStorage.collapsed)
            }
            this.collapseState = vLocalStorage.collapsed;
            (this.element.style as any) = vLocalStorage.element;
            (this.sizer.style as any) = vLocalStorage.sizer;
            (this.sibling.style as any) = vLocalStorage.sibling;
            this.elementIsFixed = vLocalStorage.elementIsFixed;
            this.siblingIsFixed = vLocalStorage.siblingIsFixed;
            this.elementIsMin = vLocalStorage.elementIsMin;
            this.siblingIsMin = vLocalStorage.siblingIsMin;
            this._setIcon()
            this.isLoadedFromLocalStorage = true;
            return;
        } else if (!this.initiallyCollapsed && !this.initiallyExpanded) {
            this.addAnimationClasses();
            animationsAdded = true;
        }

        this.sibling.style.marginLeft = '';
        this.sibling.style.marginRight = '';

        if (!this.horizontal) {
            if (this._props.fixedSibling) {
                this.sibling.style.width = this.element.style.width;
                this.element.style.width = '';
            }
            if (this.element.style.width.endsWith("px")) {
                this.sibling.style.width = this.sibling.style.width ? this.sibling.style.width : "100%"
            } else {
                this.sibling.style.width = this.sibling.style.width ? this.sibling.style.width : (100 - (+this.element.style.width.replace("%", ""))) + "%";
            }
            if (this.element.style.width.endsWith("px")) {
                this.elementIsFixed = true;
                this.element.style.minWidth = this.element.style.width;
                this.element.style.maxWidth = this.element.style.width;
            } else if (this.sibling.style.width.endsWith("px")) {
                this.siblingIsFixed = true
                this.sibling.style.minWidth = this.sibling.style.width;
                this.sibling.style.maxWidth = this.sibling.style.width;
            } else if (+this.element.style.width.replace("%", "") <= +this.sibling.style.width.replace("%", "")) {

                this.elementIsMin = true
            } else if (+this.element.style.width.replace("%", "") > +this.sibling.style.width.replace("%", "")) {
                this.siblingIsMin = true
            }
        } else {
            if (this.element.style.height.endsWith("px")) {
                this.sibling.style.height = this.sibling.style.height ? this.sibling.style.height : "100%"
            } else {
                this.sibling.style.height = this.sibling.style.height ? this.sibling.style.height : (100 - (+this.element.style.height.replace("%", ""))) + "%";
            }

            if (this.element.style.height.endsWith("px")) {
                this.elementIsFixed = true;
                this.element.style.minHeight = this.element.style.height;
                this.element.style.maxHeight = this.element.style.height;
            } else if (this.sibling.style.height.endsWith("px")) {
                this.siblingIsFixed = true;
                this.sibling.style.minHeight = this.sibling.style.height;
                this.sibling.style.maxHeight = this.sibling.style.height;
            } else if (+this.element.style.height.replace("%", "") <= +this.sibling.style.height.replace("%", "")) {
                this.elementIsMin = true
            } else if (+this.element.style.height.replace("%", "") > +this.sibling.style.height.replace("%", "")) {
                this.siblingIsMin = true
            }
        }

        if (!animationsAdded) {
            if (this.bootstrapTransitionPromise?.value) {
                this.bootstrapTransitionPromise.value.then(() => {
                    if (this.initiallyCollapsed) {
                        this.collapseTo(this.direction);
                    } else if (this.initiallyExpanded) {
                        this.collapseTo(this._reverseDirection(this.direction));
                    }
                });
            } else {
                if (this.initiallyCollapsed) {
                    this.collapseTo(this.direction);
                } else if (this.initiallyExpanded) {
                    this.collapseTo(this._reverseDirection(this.direction));
                }
            }
        }
    }

    private _addTransitionEvents() {
        if (!this.listener) {
            this.element.addEventListener('transitionend', this._transitionEnd.bind(null, this));
            this.sibling?.addEventListener('transitionend', this._transitionEnd.bind(null, this));
        }
        this.listener = true;
    }

    private _transitionEnd(that: Sizer, event: TransitionEvent) {
        if ((event.target as HTMLElement).tagName === "I") {
            return
        }
        if (that.element !== event.target) {
            return
        }
        if (that.sibling == null) {
            return;
        }

        if (that.horizontal === false) {
            if (that.siblingIsFixed && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.sibling.style.width = that.sibling.clientWidth + "px";
                that.sibling.style.maxWidth = that.sibling.clientWidth + "px";
                that.sibling.style.minWidth = that.sibling.clientWidth + "px";
                that.element.style.width = "100%";
                that.element.style.maxWidth = "";
                that.element.style.minWidth = "";
            }
            if (that.elementIsFixed && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.element.style.width = that.sizer.style.maxWidth;
                that.element.style.maxWidth = that.sizer.style.maxWidth;
                that.element.style.minWidth = that.sizer.style.maxWidth;

                that.sibling.style.width = "100%";
                that.sibling.style.maxWidth = "";
                that.sibling.style.minWidth = "";
            } else if (that.elementIsMin && !that.sizer.classList.contains("collapsedRight") && !that.sizer.classList.contains("collapsedLeft")) {
                that.sibling.style.maxWidth = "";
                that.sibling.style.minWidth = "";
            }
        } else {

            if (that.siblingIsFixed && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.sibling.style.height = that.sibling.clientHeight + "px";
                that.sibling.style.maxHeight = that.sibling.clientHeight + "px";
                that.sibling.style.minHeight = that.sibling.clientHeight + "px";
                that.element.style.height = "100%";
                that.element.style.maxHeight = "";
                that.element.style.minHeight = "";
            }
            if (that.elementIsFixed && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.element.style.height = that.sizer.style.maxHeight;
                that.element.style.maxHeight = that.sizer.style.maxHeight;
                that.element.style.minHeight = that.sizer.style.maxHeight;

                that.sibling.style.height = "100%";
                that.sibling.style.maxHeight = "";
                that.sibling.style.minHeight = "";
            } else if (that.elementIsMin && !that.sizer.classList.contains("collapsedBottom") && !that.sizer.classList.contains("collapsedTop")) {
                that.sibling.style.maxHeight = "";
                that.sibling.style.minHeight = "";
            }
        }
    }

    private _fireResize() {
        requestAnimationFrame(() => {
            window.dispatchEvent(new Event('resize'));
            this.eventHanlder?.emit('Resized', this.element, this.sibling!);
            this._vueEmit('resized', this.element, this.sibling);
        })
    }

    private _fireResizeAfterAnimation() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, this.ANIMATION_DURATION + 1);
    }

    addAnimationClasses = () => {
        this.element.classList.add("margin-sizer-animate");
        this.sibling?.classList.add("margin-sizer-animate");
    };

    removeAnimationClasses = () => {
        this.element.classList.remove("margin-sizer-animate");
        this.sibling?.classList.remove("margin-sizer-animate");
    };

    private _addWidthAnimationClasses = () => {
        this.element.classList.add("width-sizer-animate");
        this.sibling?.classList.add("width-sizer-animate");

        setTimeout(() => {
            this.element.classList.remove("width-sizer-animate");
            this.sibling?.classList.remove("width-sizer-animate");
        }, this.ANIMATION_DURATION + 1);
    };

    private _addHeighthAnimationClasses = () => {
        this.element.classList.add("height-sizer-animate");
        this.sibling.classList.add("height-sizer-animate");

        setTimeout(() => {
            this.element.classList.remove("height-sizer-animate");
            this.sibling?.classList.remove("height-sizer-animate");
        }, this.ANIMATION_DURATION + 1);
    };

    onMouseDown = (event: MouseEvent) => {
        this.addAnimationClasses();

        if (this.sizer.classList.contains("collapsedTop") || this.sizer.classList.contains("collapsedBottom") || this.sizer.classList.contains("collapsedLeft") || this.sizer.classList.contains("collapsedRight") || event.target?.tagName === "I") {
            return;
        }
        this.sibling?.classList.add("pointer-events");
        this.element.classList.add("pointer-events");
        if (event.target?.tagName === "DIV") {
            document.body.addEventListener("mousemove", this.onMove);
            document.body.addEventListener("touchmove", this.onMove);
            document.body.addEventListener("mouseup", this.onMoveEnd);
            document.body.addEventListener("touchend", this.onMoveEnd);
        }
        if (event.type !== 'touchstart') {
            event.preventDefault();
        }
    }

    onMove = event => {
        this._setSizes(event);
        if (this.eventHanlder) {
            window.requestAnimationFrame(() => {
                this.eventHanlder?.emit('Resizing', this.element, this.sibling!);
                this._vueEmit('resizing', this.element, this.sibling);
            });
        }
    }

    onMoveEnd = event => {
        this._setSizes(event, () => {
            if (this.elementIsFixed) {
                if (this.horizontal) {
                    this.element.style.minHeight = this.element.getBoundingClientRect().height + "px";
                    this.element.style.maxHeight = this.element.getBoundingClientRect().height + "px";
                    this.sibling.style.height = "100%";
                } else {
                    this.element.style.minWidth = this.element.getBoundingClientRect().width + "px";
                    this.element.style.maxWidth = this.element.getBoundingClientRect().width + "px";
                    this.sibling.style.width = "100%";
                }

            } else if (this.siblingIsFixed) {
                if (this.horizontal) {
                    this.sibling.style.minHeight = this.sibling.getBoundingClientRect().height + "px";
                    this.sibling.style.maxHeight = this.sibling.getBoundingClientRect().height + "px";
                    this.element.style.height = "100%";
                } else {
                    this.sibling.style.minWidth = this.sibling.getBoundingClientRect().width + "px";
                    this.sibling.style.maxWidth = this.sibling.getBoundingClientRect().width + "px";
                    this.element.style.width = "100%";
                }
            }
            this._fireResize();
            this._saveToLocalStorage();
        });
        document.body.removeEventListener("mousemove", this.onMove);
        document.body.removeEventListener("touchmove", this.onMove);
        document.body.removeEventListener("mouseup", this.onMoveEnd);
        document.body.removeEventListener("touchend", this.onMoveEnd);
        this.element.classList.remove("pointer-events");
        this.sibling.classList.remove("pointer-events");

    }

    private _saveToLocalStorage() {
        if (this._props.skipLocalStorage) { return; }
        var vCollapsed;
        this.sizer.classList.forEach((item) => {
            if (item.startsWith("collapsed")) {
                vCollapsed = item;
            }
        });
        this._storage.storeValue({
            element: this.element.getAttribute('style'),
            sibling: this.sibling!.getAttribute('style'),
            sizer: this.sizer.getAttribute('style'),
            elementIsFixed: this.elementIsFixed,
            siblingIsFixed: this.siblingIsFixed,
            elementIsMin: this.elementIsMin,
            siblingIsMin: this.siblingIsMin,
            collapsed: vCollapsed as typeof Sizer.prototype.collapseState ?? undefined,
        });
    }
    private _getFromLocalStorage() {
        return this._storage.getStoredValue();
        // return JSON.parse(localStorage.getItem(this.id));
    }

    private _setSizes(event, callback) {
        if (this.debounce) cancelAnimationFrame(this.debounce);
        this.debounce = window.requestAnimationFrame(_ => {
            if (this.elementIsFixed || this.elementIsMin) {
                this._setSizesElementPanel(this.horizontal, event);
            } if (this.siblingIsFixed || this.siblingIsMin) {
                this._setSizesSiblingPanel(this.horizontal, event);
            }
            if (callback && typeof callback == "function") {
                callback()
            }
        });
    }

    private _setSizesElementPanel(pHorizontal, event) {
        if (!pHorizontal) {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vLeft = this.direction === "left" ? this._getXFromEvent(event) - vContainerRects.x : vContainerRects.width - (this._getXFromEvent(event) - vContainerRects.left);

            // Check if new size is less than max-width if provided
            if (this.maxWidth != null) {
                const parsedMaxWidth = parseInt(this.maxWidth);
                if (this.maxWidth.includes('%')) {
                    if (((vLeft / vContainerRects.width) * 100) > parsedMaxWidth) { vLeft = (parsedMaxWidth / 100) * vContainerRects.width; }
                } else {
                    if (vLeft > parsedMaxWidth) { vLeft = parsedMaxWidth; }
                }
            }

            // Check if new size is greater than min-width if provided
            if (this.minWidth != null) {
                const parsedminWidth = parseInt(this.minWidth);
                if (this.minWidth.includes('%')) {
                    if (((vLeft / vContainerRects.width) * 100) < parsedminWidth) { vLeft = (parsedminWidth / 100) * vContainerRects.width; }
                } else {
                    if (vLeft < parsedminWidth) { vLeft = parsedminWidth; }
                }
            }

            this.sibling.style.width = (100 - ((vLeft / vContainerRects.width) * 100)) + '%'
            this.element.style.minWidth = "";
            this.element.style.maxWidth = "";

            if (this.elementIsMin) {
                this.element.style.width = ((vLeft / vContainerRects.width) * 100) + '%'
            } else {
                this.element.style.width = vLeft + "px";
            }
        } else {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vTop = this.direction === "top" ? this._getYFromEvent(event) - vContainerRects.y : vContainerRects.height - (this._getYFromEvent(event) - vContainerRects.top);

            // Check if new size is less than max-height if provided
            if (this.maxHeight != null) {
                const parsedMaxHeight = parseInt(this.maxHeight);
                if (this.maxHeight.includes('%')) {
                    if (((vTop / vContainerRects.height) * 100) > parsedMaxHeight) { vTop = (parsedMaxHeight / 100) * vContainerRects.height; }
                } else {
                    if (vTop > parsedMaxHeight) { vTop = parsedMaxHeight; }
                }
            }

            // Check if new size is greater than min-height if provided
            if (this.minHeight != null) {
                const parsedminHeight = parseInt(this.minHeight);
                if (this.minHeight.includes('%')) {
                    if (((vTop / vContainerRects.height) * 100) < parsedminHeight) { vTop = (parsedminHeight / 100) * vContainerRects.height; }
                } else {
                    if (vTop < parsedminHeight) { vTop = parsedminHeight; }
                }
            }


            this.sibling.style.height = (100 - ((vTop / vContainerRects.height) * 100)) + '%'
            this.element.style.minHeight = "";
            this.element.style.maxHeight = "";
            if (this.elementIsMin) {
                this.element.style.height = ((vTop / vContainerRects.height) * 100) + '%'
            } else {
                this.element.style.height = vTop + "px";
                this.element.style.minHeight = vTop + "px";
                this.element.style.maxHeight = vTop + "px";
            }
        }
    }

    private _setSizesSiblingPanel(pHorizontal, event) {
        if (!pHorizontal) {
            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            //let vRight = (event.x - vContainerRects.x - vContainerRects.width) * -1;
            let vRight = this.direction === "right" ? this._getXFromEvent(event) - vContainerRects.x : vContainerRects.width - (this._getXFromEvent(event) - vContainerRects.left);

            // Check if new size is less than max-width if provided
            if (this.maxWidth != null) {
                const parsedMaxWidth = parseInt(this.maxWidth);
                if (this.maxWidth.includes('%')) {
                    if ((((vContainerRects.width - vRight) / vContainerRects.width) * 100) > parsedMaxWidth) { vRight = ((100 - parsedMaxWidth) / 100) * vContainerRects.width; }
                } else {
                    if (vContainerRects.width - vRight > parsedMaxWidth) { vRight = vContainerRects.width - parsedMaxWidth; }
                }
            }

            if (this.siblingIsMin) {
                this.sibling.style.width = (((vRight / vContainerRects.width) * 100)) + '%'
            } else {
                this.sibling.style.width = vRight + "px";
            }
            this.element.style.width = (100 - ((vRight / vContainerRects.width) * 100)) + '%';

            if (this.minWidth)
                this.element.style.minWidth = this.minWidth;

            this.sibling.style.minWidth = "";
            this.sibling.style.maxWidth = "";
        } else {

            let vContainerRects = this.element.parentElement.getBoundingClientRect();
            let vBottom = this.direction === "bottom" ? this._getYFromEvent(event) - vContainerRects.y : vContainerRects.height - (this._getYFromEvent(event) - vContainerRects.top);

            // Check if new size is less than max-height if provided
            if (this.maxHeight != null) {
                const parsedMaxHeight = parseInt(this.maxHeight);
                if (this.maxHeight.includes('%')) {
                    if ((((vContainerRects.height - vBottom) / vContainerRects.height) * 100) > parsedMaxHeight) { vBottom = ((100 - parsedMaxHeight) / 100) * vContainerRects.height; }
                } else {
                    if (vContainerRects.height - vBottom > parsedMaxHeight) { vBottom = vContainerRects.height - parsedMaxHeight; }
                }
            }

            //let vBottom = (event.y - vContainerRects.y - vContainerRects.height) * -1;
            if (this.siblingIsMin) {
                //this.sizer.style.bottom = ((vContainerRects.height-this.element.clientHeight)/vContainerRects.height) * 100 + "%";
                this.sibling.style.height = (((vBottom / vContainerRects.height) * 100)) + '%'
            } else {
                this.sizer.style.bottom = this.sibling.clientHeight + "px";
            }
            this.element.style.height = (100 - ((vBottom / vContainerRects.height) * 100)) + '%';

            if (this.minHeight)
                this.element.style.minHeight = this.minHeight;

            this.sibling.style.minHeight = "";
            this.sibling.style.maxHeight = "";
        }
    }

    async left() {
        return this.collapseTo('left');
    }

    async right() {
        return this.collapseTo('right');
    }

    async top() {
        return this.collapseTo('top');
    }

    async bottom() {
        return this.collapseTo("bottom");
    }

    private _reverseDirection(pDirection: typeof this.direction) {
        switch (pDirection) {
            case 'bottom':
                return 'top';
            case 'top':
                return 'bottom';
            case 'left':
                return 'right';
            case 'right':
                return 'left';
        }
    }

    private _getCollapsedDireciton(pDirection: typeof this.direction) {
        switch (pDirection) {
            case 'bottom':
                return 'collapsedBottom';
            case 'top':
                return 'collapsedTop';
            case 'left':
                return 'collapsedLeft';
            case 'right':
                return 'collapsedRight';
        }
    }

    private _styleSize(pPrefix?: 'min' | 'max'): 'width' | 'height' | 'minWidth' | 'minHeight' | 'maxHeight' | 'maxWidth' {
        let property = '';
        if (this.horizontal) {
            property = pPrefix ? 'Height' : 'height';
        } else {
            property = pPrefix ? 'Width' : 'width';
        }

        if (pPrefix) {
            return `${pPrefix}${property}`;
        } else {
            return property;
        }
    }

    private _styleMargin(pDirection: typeof this.direction): 'marginTop' | 'marginLeft' | 'marginBottom' | 'marginRight' {
        switch (pDirection) {
            case 'bottom':
                return 'marginBottom';
            case 'top':
                return 'marginTop';
            case 'left':
                return 'marginLeft';
            case 'right':
                return 'marginRight';
        }
    }

    private _getCollapseState() {
        let collapsed = this._getFromLocalStorage()?.collapsed
        if (this.initiallyCollapsed) {
            if (collapsed === undefined || collapsed === null) {
                return null;
            } else { return collapsed }

        } else if (this.initiallyExpanded) {
            if (collapsed === undefined || collapsed === null) {
                return null;
            } else { return collapsed }
        } else {
            return collapsed || null;
        }
    }


    async collapseTo(pDirection: typeof this.direction, pSkipLocalStorage?: boolean, pIsRepeating = false) {
        this._addTransitionEvents();
        if (this.element == null || this.sibling == null) { return; }
        let vContainerRects = this.element.parentElement!.getBoundingClientRect();
        const repeatAction = !pSkipLocalStorage && !pIsRepeating && this._isBelowCollapseAt();

        const reversedCollapseClass = this._getCollapsedDireciton(this._reverseDirection(pDirection));
        if (this.sizer.classList.contains(reversedCollapseClass)) {
            // Going back from collapsed state to showing both element and sibling
            this.sizer.classList.remove(reversedCollapseClass);
            this.collapseState = null;
            if (this.siblingIsFixed) {
                const containerRectSize = vContainerRects[this.horizontal ? 'height' : 'width'];
                const sizerMaxSize = parseFloat(this.sizer.style[this._styleSize('max')].replace('px', ''));
                this.sibling.style[this._styleSize()] = (containerRectSize - sizerMaxSize) + 'px';
                this.sibling.style[this._styleSize('min')] = (containerRectSize - sizerMaxSize) + 'px';
                this.sibling.style[this._styleSize('max')] = (containerRectSize - sizerMaxSize) + 'px';

                this.element.style[this._styleMargin(pDirection)] = '';
                this.element.style[this._styleSize()] = '100%';
                this.element.style[this._styleSize('min')] = '';
                this.element.style[this._styleSize('max')] = '';
            } else if (this.elementIsFixed) {
                if (pDirection === this.direction) {
                    this.sibling.style[this._styleMargin(pDirection)] = '';
                    await this._animationPromise();
                    const containerRectSize = vContainerRects[this.horizontal ? 'height' : 'width'];
                    const siblingMaxSize = parseFloat(this.sibling.style[this._styleSize('max')].replace('px', ''));
                    this.element.style[this._styleSize()] = (containerRectSize - siblingMaxSize) + 'px';
                    this.element.style[this._styleSize('min')] = (containerRectSize - siblingMaxSize) + 'px';
                    this.element.style[this._styleSize('max')] = (containerRectSize - siblingMaxSize) + 'px';
                    this.sibling.style[this._styleSize()] = '100%';
                    this.sibling.style[this._styleSize('min')] = '';
                    this.sibling.style[this._styleSize('max')] = '';
                }
            } else if (this.elementIsMin || this.siblingIsMin) {
                // if (this.horizontal) {
                //     this._addHeighthAnimationClasses();
                // } else {
                //     this._addWidthAnimationClasses();
                // }

                this.sibling.style[this._styleSize()] = this.sizer.style[this._styleSize('max')];
                this.element.style[this._styleSize()] = (100 - (+this.sizer.style[this._styleSize('max')].replace('%', ''))) + '%';
                if (pDirection === this.direction) {
                    this.sibling.style[this._styleMargin(this._reverseDirection(pDirection))] = '';
                }
            }
            this._setIcon();
            this.element.style[this._styleMargin(this._reverseDirection(pDirection))] = '';
        } else {
            if (this.elementIsFixed) {
                if (this._reverseDirection(pDirection) === this.direction) {
                    const sbClientSize = this.horizontal ? this.sibling.clientHeight : this.sibling.clientWidth;
                    const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                    this.sibling.style[this._styleSize()] = sbClientSize + 'px';
                    this.sibling.style[this._styleSize('max')] = sbClientSize + 'px';
                    this.sibling.style[this._styleSize('min')] = sbClientSize + 'px';

                    this.element.style[this._styleSize()] = '100%';
                    this.element.style[this._styleSize('min')] = '';
                    this.element.style[this._styleSize('max')] = '';
                    this.sizer.style[this._styleSize('max')] = elClientSize + 'px';
                    this.sibling.style[this._styleMargin(this._reverseDirection(pDirection))] = sbClientSize * -1 + 'px';
                } else {
                    const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                    this.sibling.style[this._styleSize()] = '100%';
                    this.sibling.style[this._styleSize('min')] = '';
                    this.sibling.style[this._styleSize('max')] = '';
                    this.sizer.style[this._styleSize('max')] = elClientSize + 'px';
                    this.element.style[this._styleMargin(pDirection)] = elClientSize * -1 + 'px';
                }
            } else if (this.siblingIsFixed) {
                if (this._reverseDirection(pDirection) === this.direction) {
                    const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                    const sbClientSize = this.horizontal ? this.sibling.clientHeight : this.sibling.clientWidth;
                    this.element.style[this._styleSize()] = elClientSize + 'px';
                    this.element.style[this._styleSize('max')] = elClientSize + 'px';
                    this.element.style[this._styleSize('min')] = elClientSize + 'px';

                    this.sibling.style[this._styleSize()] = '100%';
                    this.sibling.style[this._styleSize('min')] = '';
                    this.sibling.style[this._styleSize('max')] = '';

                    this.sizer.style[this._styleSize('max')] = sbClientSize + 'px';
                    this.element.style[this._styleMargin(this._reverseDirection(pDirection))] = elClientSize * -1 + 'px';
                } else {
                    const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                    this.sibling.style[this._styleSize()] = '100%';
                    this.sibling.style[this._styleSize('min')] = '';
                    this.sibling.style[this._styleSize('max')] = '';

                    this.element.style[this._styleSize()] = elClientSize + 'px';
                    this.element.style[this._styleSize('max')] = elClientSize + 'px';
                    this.element.style[this._styleSize('min')] = elClientSize + 'px';
                    this.sizer.style[this._styleSize('max')] = elClientSize + 'px';
                    this.element.style[this._styleMargin(pDirection)] = elClientSize * -1 + 'px';
                }
            } else if (this.elementIsFixed) {
                const sbClientSize = this.horizontal ? this.sibling.clientHeight : this.sibling.clientWidth;
                const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                this.sizer.style[this._styleSize('max')] = elClientSize + 'px';
                this.sibling.style[this._styleMargin(pDirection)] = sbClientSize * -1 + 'px';
            } else if (this.elementIsMin || this.siblingIsMin) {
                // if (this.horizontal) {
                //     this._addHeighthAnimationClasses();
                // } else {
                //     this._addWidthAnimationClasses();
                // }

                this.sizer.style[this._styleSize('max')] = this.sibling.style[this._styleSize()];
                if (this.direction === this._reverseDirection(pDirection)) {
                    const sbClientSize = this.horizontal ? this.sibling.clientHeight : this.sibling.clientWidth;
                    this.sibling.style[this._styleMargin(pDirection)] = sbClientSize * -1 + 'px';
                    this.sibling.style[this._styleSize()] = sbClientSize + 'px';
                    this.element.style[this._styleSize()] = "100%";
                } else {
                    const elClientSize = this.horizontal ? this.element.clientHeight : this.element.clientWidth;
                    this.element.style[this._styleMargin(pDirection)] = elClientSize * -1 + 'px';
                    this.element.style[this._styleSize()] = elClientSize + 'px';
                    this.sibling.style[this._styleSize()] = '100%';
                }
            }

            this.sizer.classList.add(this._getCollapsedDireciton(pDirection));
            this.collapseState = this._getCollapsedDireciton(pDirection);
            this._setIcon();
        }
        if (!repeatAction) {
            this._fireResizeAfterAnimation();
        }
        if (!pSkipLocalStorage && !repeatAction) {
            this._saveToLocalStorage();
        }

        if (repeatAction) {
            this.collapseTo(pDirection, pSkipLocalStorage, true);
        }
    }

    private _setIcon() {
        if (this.sizer.classList.contains('collapsedLeft')) {
            this.setIconsState('right');
        } else if (this.sizer.classList.contains('collapsedRight')) {
            this.setIconsState('left');
        } else if (this.sizer.classList.contains('collapsedTop')) {
            this.setIconsState('bottom');
        } else if (this.sizer.classList.contains('collapsedBottom')) {
            this.setIconsState('top');
        } else {
            if (this.expand && this.collapse) {
                this.setIconsState(this.horizontal ? 'vertical' : 'horizontal');
            } else {
                if (this.collapse) {
                    this.setIconsState(this.direction);
                } else {
                    if (!this.horizontal) {
                        if (this.direction === 'right') {
                            this.setIconsState('left');
                        } else {
                            this.setIconsState('right');
                        }
                    } else {
                        if (this.direction === 'top') {
                            this.setIconsState('bottom');
                        } else {
                            this.setIconsState('top');
                        }
                    }
                }
            }
        }
    }

    attemptAutoExpand() {
        let collapsedState: typeof Sizer.prototype.collapseState = null;
        this.sizer.classList.forEach((item) => {
            if (item.startsWith("collapsed")) {
                collapsedState = item as typeof Sizer.prototype.collapseState;
            }
        });
        if (collapsedState == null) { return; }
        const storedValue =  this._getFromLocalStorage();
        if (storedValue?.collapsed != null) { return; }
        this.collapseTo(this._reverseDirection(this.direction), true);
        this._vueEmit("collapsed", this._reverseDirection(this.direction))
    }

    attemptAutoCollapse() {
        let collapsedState: typeof Sizer.prototype.collapseState = null;
        this.sizer.classList.forEach((item) => {
            if (item.startsWith("collapsed")) {
                collapsedState = item as typeof Sizer.prototype.collapseState;
            }
        });
        if (collapsedState != null) { return; }
        const storedValue =  this._getFromLocalStorage();
        if (storedValue?.collapsed != null) { return; }
        this.collapseTo(this.direction, true);
        this._vueEmit("collapsed", this.direction)
    }

    /** Clears disrupting w-100 and h-100 classes from the containers */
    clearContainerClasses() {
        if (this.horizontal) {
            if (this.sibling?.classList?.contains('h-100')) {
                this.sibling.classList.remove('h-100');
                this.sibling.style.flexGrow = '';
            }
            if (this.element?.classList?.contains('h-100')) {
                this.element.classList.remove('h-100');
                this.element.style.flexGrow = '';
            }
        } else {
            if (this.sibling?.classList?.contains('w-100')) {
                this.sibling.classList.remove('w-100');
                this.sibling.style.flexGrow = '';
            }
            if (this.element?.classList?.contains('w-100')) {
                this.element.classList.remove('w-100');
                this.element.style.flexGrow = '';
            }
        }
    }

    /** Set sizer state */
    setState(pState: ISizerStorageItem, pSaveToLocalStorage?: boolean) {
        if (this.sibling == null) {
            throw new TypeError('Cannot set state on malconfigured sizer, missing sibling element');
        }

        if (pState.collapsed) {
            this.sizer.classList.add(pState.collapsed)
        } else {
            this.sizer.classList.remove('collapsedBottom', 'collapsedTop', 'collapsedLeft', 'collapsedRight');
        }
        this.collapseState = pState.collapsed;
        (this.element.style as any) = pState.element;
        (this.sizer.style as any) = pState.sizer;
        (this.sibling.style as any) = pState.sibling;
        this.elementIsFixed = pState.elementIsFixed;
        this.siblingIsFixed = pState.siblingIsFixed;
        this.elementIsMin = pState.elementIsMin;
        this.siblingIsMin = pState.siblingIsMin;
        this._setIcon()

        if (pSaveToLocalStorage) {
            this._saveToLocalStorage();
        }
    }

    /** Get current sizer state */
    getState(): ISizerStorageItem {
        let collapsedState: typeof Sizer.prototype.collapseState = null;
        this.sizer.classList.forEach((item) => {
            if (item.startsWith("collapsed")) {
                collapsedState = item as typeof Sizer.prototype.collapseState;
            }
        });

        return {
            element: this.element.getAttribute('style'),
            sibling: this.sibling!.getAttribute('style'),
            sizer: this.sizer.getAttribute('style'),
            elementIsFixed: this.elementIsFixed,
            siblingIsFixed: this.siblingIsFixed,
            elementIsMin: this.elementIsMin,
            siblingIsMin: this.siblingIsMin,
            collapsed: collapsedState ?? undefined,
        };
    }

    /** Inverse the given sizer state, returns a new state object */
    inverseState(pState: ISizerStorageItem): ISizerStorageItem {
        let collapsed = pState.collapsed;
        switch (collapsed) {
            case 'collapsedBottom':
                collapsed = 'collapsedTop';
                break;
            case 'collapsedTop':
                collapsed = 'collapsedBottom';
                break;
            case 'collapsedLeft':
                collapsed = 'collapsedRight';
                break;
            case 'collapsedRight':
                collapsed = 'collapsedLeft';
                break;
            default:
                collapsed = undefined;
        }
        let sizer = pState.sizer?.split(';').map(style => {
            const attribute = style.split(':')[0]?.trim();;
            if (['left', 'right', 'top', 'bottom'].includes(attribute)) {
                const reversedDirection = this._reverseDirection(attribute as typeof this.direction);
                return style.replace(attribute, reversedDirection);
            }
            return style;;
        }).join(';');
        let element = pState.element?.split(';').map(style => {
            const attribute = style.split(':')[0]?.trim();;
            if (['margin-left', 'margin-right', 'margin-top', 'margin-bottom'].includes(attribute)) {
                const reversedDirection = `margin-${this._reverseDirection(attribute.split('-')[1] as typeof this.direction)}`;
                return style.replace(attribute, reversedDirection);
            }
            return style
        }).join(';') ?? pState.element;
        let sibling = pState.sibling?.split(';').map(style => {
            const attribute = style.split(':')[0]?.trim();;
            if (['margin-left', 'margin-right', 'margin-top', 'margin-bottom'].includes(attribute)) {
                const reversedDirection = `margin-${this._reverseDirection(attribute.split('-')[1] as typeof this.direction)}`;
                return style.replace(attribute, reversedDirection);
            }
            return style
        }).join(';') ?? pState.sibling;

        return {
            collapsed: collapsed,
            element: element,
            elementIsFixed: pState.elementIsFixed,
            elementIsMin: pState.elementIsMin,
            sibling: sibling,
            siblingIsFixed: pState.siblingIsFixed,
            siblingIsMin: pState.siblingIsMin,
            sizer: sizer ?? null
        };
    }

    private _getXFromEvent(event) {
        if (event.type === 'touchmove') {
            return event.touches[0].clientX;
        } else {
            return event.x;
        }
    }

    private _getYFromEvent(event) {
        if (event.type === 'touchmove') {
            return event.touches[0].clientY;
        } else {
            return event.y;
        }
    }

    private _animationPromise() {
        return new Promise(resolve => setTimeout(resolve, this.ANIMATION_DURATION));
    }

    private _isBelowCollapseAt() {
        if (!this.collapseAt) { return false; }
        const windowWidth = window.innerWidth;
        const collapseAtWidth = parseInt(this.collapseAt!);
        return windowWidth <= collapseAtWidth
    }

}

export interface ISizerStorageItem {
    element: string | null,
    sibling: string | null,
    sizer: string | null,
    elementIsFixed?: boolean,
    siblingIsFixed?: boolean,
    elementIsMin?: boolean,
    siblingIsMin?: boolean,
    collapsed: undefined | 'collapsedLeft' | 'collapsedRight' | 'collapsedTop' | 'collapsedBottom'
}

interface ISizerStorageBaseline {
    collapse: boolean,
    expand: boolean,
    width: string,
    height: string,
    initiallyCollapsed: boolean,
    initiallyExpanded: boolean,
    maxWidth: boolean,
    maxHeight: boolean,
    minWidth: boolean,
    minHeight: boolean
};
